const imagePath = `${process.env.NEXT_PUBLIC_IMAGES_CDN_PATH}/logos`;

export default {
  akin: `${imagePath}/`,
  mews: `${imagePath}/mews.svg`,
  cloudbeds: `${imagePath}/cloudbeds.png`,
  ezee: `${imagePath}/ezee.png`,

  whatsapp: `${imagePath}/whatsApp-brand.png`,
  instagram: `${imagePath}/instagram-brand.png`,
  facebook: `${imagePath}/facebook-brand.png`,

  website: `${imagePath}/Website_icon.png`,
  google: `${imagePath}/Gmail_icon.png`,
  bookingCom: `${imagePath}/Bookingcom_icon.png`,
  freshdesk: `${imagePath}/Freshdesk_icon.png`,
  freshchat: `${imagePath}/Freshchat_icon.png`,
  email: `${imagePath}/Email_icon.png`,
  zendesk: `${imagePath}/Zendesk_icon.png`,
  slack: `${imagePath}/Slack_icon.png`,
  sms: `${imagePath}/SMS_icon.png`,
  bookboost: `${imagePath}/Bookboost_icon.png`,
  phone: `${imagePath}/Phone_icon.png`,
};
