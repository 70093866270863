import { createStyles } from '@mantine/core';

const useSharedStyles = createStyles((theme) => ({
  halfWidth: {
    width: 'calc(50% - 5px)',
    [theme.fn.smallerThan('lg')]: {
      width: '100%',
    },
  },
  oneThirdWidth: {
    width: 'calc(33.33% - 5px)',
    [theme.fn.smallerThan('xl')]: {
      width: 'calc(50% - 5px)',
    },
    [theme.fn.smallerThan('lg')]: {
      width: '100%',
    },
  },
  twoThirdWidth: {
    width: 'calc(66.66% - 5px)',
    [theme.fn.smallerThan('xl')]: {
      width: 'calc(50% - 5px)',
    },
    [theme.fn.smallerThan('lg')]: {
      width: '100%',
    },
  },
  graphRow: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
  },
  overflowYGraph: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    ...theme.other.hideScrollBar,
  },
  overflowGraph: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    overflowX: 'auto',
    ...theme.other.hideScrollBar,
  },
  langContent: {
    height: '100%',
  },
}));

export default useSharedStyles;
