import { useMemo } from 'react';
import { createStyles } from '@mantine/core';

import { getChannelList } from '../helpers';

import PropertyGraphs from '../PropertyGraphs';
import GroupGraphs from '../GroupGraphs';
import FilterPanel from '../common/FilterPanel';

const useStyles = createStyles((theme) => ({
  root: {
    ...theme.other.hideScrollBar,
  },
  filterPanelContainer: {
    position: 'sticky',
    top: 16.5,
    backgroundColor: '#f1f4f5',
    zIndex: 11, // as graphs has z-index 10
    paddingTop: 14,
    paddingBottom: 14,
    [theme.fn.smallerThan('xs')]: {
      top: 43,
      paddingTop: 4,
      paddingBottom: 4,
    },
  },
}));

export default function HomeOverviewPage({
  isGroup,
  configuredChannels,
  reportData,
  selectedOptions,
  setSelectedOptions,
  handleRefresh,
}) {
  const { classes } = useStyles();

  const channelList = useMemo(
    () => getChannelList(configuredChannels),
    [configuredChannels]
  );

  const onSelectChannel = (channelID) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      channel: channelID,
    }));
  };

  const onSelectPeriod = (periodID) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      period: periodID,
    }));
  };

  const onSelectFilter = (filter) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      filter,
    }));
  };

  return (
    <div className={classes.root}>
      <div className={classes.filterPanelContainer}>
        <FilterPanel
          onRefresh={() => handleRefresh()}
          channelList={channelList}
          disableFilter
          onSelectChannel={onSelectChannel}
          selectedChannel={selectedOptions.channel}
          onSelectPeriod={onSelectPeriod}
          selectedPeriod={selectedOptions.period}
          selectedFilter={selectedOptions.filter}
          setSelectedFilter={onSelectFilter}
        />
      </div>

      {isGroup ? (
        <GroupGraphs
          selectedChannel={selectedOptions.channel}
          selectedPeriod={selectedOptions.period}
          reportData={reportData}
        />
      ) : (
        <PropertyGraphs
          selectedChannel={selectedOptions.channel}
          selectedPeriod={selectedOptions.period}
          reportData={reportData}
        />
      )}
    </div>
  );
}
