import { useMemo } from 'react';
import { t } from '@lingui/macro';
import { createStyles } from '@mantine/core';

import { getChannelList } from '../helpers';
import FilterPanel from '../common/FilterPanel';
import GraphCard from '../common/GraphCard';
import GraphInsights from '../GraphInsights';
import CSATScoresTable from '../tabular-reports/CSATScoresTable';
import CsatResponseRateGraph from '../graphs/CsatResponseRate';
import useSharedStyles from '../sharedStyles';
import { getProcessedCsatData } from '../csat-helpers';

const useStyles = createStyles((theme) => ({
  root: {
    ...theme.other.hideScrollBar,
  },
  filterPanelContainer: {
    position: 'sticky',
    top: 16.5,
    backgroundColor: '#f1f4f5',
    zIndex: 11, // as graphs has z-index 10
    paddingTop: 14,
    paddingBottom: 14,
    [theme.fn.smallerThan('xs')]: {
      top: 43,
      paddingTop: 4,
      paddingBottom: 4,
    },
  },
}));

export default function HomeCSATPage({
  configuredChannels,
  reportData,
  selectedOptions,
  setSelectedOptions,
  handleRefresh,
}) {
  const { classes } = useSharedStyles();
  const { classes: pageClasses } = useStyles();

  const channelList = useMemo(
    () => getChannelList(configuredChannels),
    [configuredChannels]
  );

  const { csatScoreTableData, csatResponseRateGraphData } = useMemo(
    () => getProcessedCsatData({ data: reportData }),
    [reportData]
  );

  const onSelectChannel = (channelID) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      channel: channelID,
    }));
  };

  const onSelectPeriod = (periodID) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      period: periodID,
    }));
  };

  const onSelectFilter = (filter) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      filter,
    }));
  };

  return (
    <div className={pageClasses.root}>
      <div className={pageClasses.filterPanelContainer}>
        <FilterPanel
          onRefresh={() => handleRefresh()}
          channelList={channelList}
          onSelectChannel={onSelectChannel}
          selectedChannel={selectedOptions.channel}
          onSelectPeriod={onSelectPeriod}
          selectedPeriod={selectedOptions.period}
          selectedFilter={selectedOptions.filter}
          setSelectedFilter={onSelectFilter}
        />
      </div>

      {selectedOptions.channel && (
        <div className={classes.graphRow}>
          <GraphCard className={classes.twoThirdWidth}>
            <GraphCard.Header>
              <GraphInsights title={t`CSAT Scores`} />
            </GraphCard.Header>
            <div className={classes.overflowGraph}>
              <CSATScoresTable data={csatScoreTableData} />
            </div>
          </GraphCard>

          <GraphCard className={classes.oneThirdWidth}>
            <GraphCard.Header>
              <GraphInsights title={t`CSAT response rate`} />
            </GraphCard.Header>
            <GraphCard.Content overflow={false}>
              <CsatResponseRateGraph graphInfo={csatResponseRateGraphData} />
            </GraphCard.Content>
          </GraphCard>
        </div>
      )}
    </div>
  );
}
