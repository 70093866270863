import React from 'react';
import { createStyles } from '@mantine/core';
import CustomSwitch from '../CustomSwitch';

const useStyles = createStyles((theme) => ({
  graphCard: {
    backgroundColor: theme.white,
    border: '1px solid #eeeeee',
    padding: 20,
    borderRadius: 8,
    marginBottom: 10,
    [theme.fn.smallerThan('lg')]: {
      padding: 8,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },
  container: {
    height: 500,
    width: '100%',
    overflow: 'hidden',
    overflowX: 'auto',
  },
  noOverflow: {
    height: '100%',
  },
  overflowContent: {
    height: '100%',
    [theme.fn.smallerThan('xs')]: {
      width: 800,
    },
  },
}));

function GraphHeader({
  children,
  showSwitch = false,
  switchList,
  selected,
  onSwitchChange,
}) {
  const { classes } = useStyles();

  return (
    <div className={classes.header}>
      <div className={classes.titleHeader}>{children}</div>
      {showSwitch && (
        <CustomSwitch
          buttonList={switchList}
          onSelect={onSwitchChange}
          selected={selected}
        />
      )}
    </div>
  );
}

function GraphBody({ className, children, overflow = true }) {
  const { cx, classes } = useStyles();
  return (
    <div className={cx(classes.container, className)}>
      <div className={overflow ? classes.overflowContent : classes.noOverflow}>
        {children}
      </div>
    </div>
  );
}

function GraphCard({ className, children }) {
  const { cx, classes } = useStyles();
  return <div className={cx(classes.graphCard, className)}>{children}</div>;
}

GraphCard.Header = GraphHeader;
GraphCard.Content = GraphBody;

export default GraphCard;
