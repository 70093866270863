import { useMemo } from 'react';
import { t } from '@lingui/macro';

import {
  AI_CHANNELS_CONFIG,
  AI_SERVICES_CHANNELS,
  CHANNEL_MODE,
} from '../ai-service-channels/constants';

import GraphCard from './common/GraphCard';
import GraphInsights from './GraphInsights';
import ResponsesGraph from './graphs/group/Responses';
import { getGroupGraphData } from './group-helpers';
import AutomationPercentGraph from './graphs/group/AutomationPercent';
import AverageSatisfactionScoreGraph from './graphs/group/AverageSatisfactionScore';
import ProblemSolvedGraph from './graphs/group/ProblemSolved';
import BookingEngineClickGraph from './graphs/group/BookingEngineClick';
import BookingEngineValueGraph from './graphs/group/BookingEngineValue';
import useSpaceSettings from '../../hooks/useSpaceSettings';
import useSharedStyles from './sharedStyles';
import CountGraph from './graphs/Count';

export default function GroupGraphs({
  selectedChannel,
  selectedPeriod,
  reportData,
}) {
  const { classes } = useSharedStyles();
  const {
    spaceSettings: { subSpaces = [] },
  } = useSpaceSettings();

  const {
    countGraphData,
    responsesGraphData,
    automationPercentGraphData,
    avgSatisfactionScoreGraphData,
    problemSolvedPercentGraphData,
    bookingEngineClickGraphData,
    bookingEngineValueGraphData,
  } = useMemo(
    () =>
      getGroupGraphData({
        period: selectedPeriod,
        data: reportData,
        subSpaces,
      }),
    [selectedPeriod, reportData, subSpaces]
  );

  return (
    <>
      <GraphCard>
        <div className={classes.overflowGraph}>
          <CountGraph data={countGraphData} />
        </div>
      </GraphCard>

      <GraphCard>
        <GraphCard.Header>
          <GraphInsights title={t`Messages by Property`} />
        </GraphCard.Header>
        <GraphCard.Content>
          <ResponsesGraph data={responsesGraphData} />
        </GraphCard.Content>
      </GraphCard>

      <GraphCard>
        <GraphCard.Header>
          <GraphInsights title={t`Automation % by Property`} />
        </GraphCard.Header>
        <GraphCard.Content>
          <AutomationPercentGraph data={automationPercentGraphData} />
        </GraphCard.Content>
      </GraphCard>

      <GraphCard>
        <GraphCard.Header>
          <GraphInsights title={t`Average Satisfaction Score by Property`} />
        </GraphCard.Header>
        <GraphCard.Content>
          <AverageSatisfactionScoreGraph data={avgSatisfactionScoreGraphData} />
        </GraphCard.Content>
      </GraphCard>

      <GraphCard>
        <GraphCard.Header>
          <GraphInsights title={t`Problem Solved % by Property`} />
        </GraphCard.Header>
        <GraphCard.Content>
          <ProblemSolvedGraph data={problemSolvedPercentGraphData} />
        </GraphCard.Content>
      </GraphCard>

      {AI_CHANNELS_CONFIG?.[selectedChannel]?.mode === CHANNEL_MODE.chat &&
        selectedChannel !== AI_SERVICES_CHANNELS.whatsApp && (
          <div className={classes.graphRow}>
            <GraphCard className={classes.halfWidth}>
              <GraphCard.Header>
                <GraphInsights title={t`Clicks by Property`} />
              </GraphCard.Header>
              <GraphCard.Content>
                <BookingEngineClickGraph data={bookingEngineClickGraphData} />
              </GraphCard.Content>
            </GraphCard>

            <GraphCard className={classes.halfWidth}>
              <GraphCard.Header>
                <GraphInsights title={t`Booking value by Property`} />
              </GraphCard.Header>
              <GraphCard.Content>
                <BookingEngineValueGraph data={bookingEngineValueGraphData} />
              </GraphCard.Content>
            </GraphCard>
          </div>
        )}
    </>
  );
}
