import LOGOS from '@akin/core-lib/logos';
import { t } from '@lingui/macro';

export const AI_CHAT_CHANNELS_CONFIG = {
  default: {
    id: 'other',
    label: 'Other',
    colorStyle: { backgroundColor: '#f1d904' },
    icon: null,
  },
  whatsapp: {
    id: 'whatsapp',
    label: 'WhatsApp',
    colorStyle: { backgroundColor: '#25d366' },
    icon: LOGOS.whatsapp,
  },
  website: {
    id: 'website',
    label: 'Website',
    colorStyle: { backgroundColor: 'orange' },
    icon: LOGOS.website,
  },
  instagram: {
    id: 'instagram',
    label: 'Instagram',
    colorStyle: {
      backgroundImage:
        'linear-gradient(to top left, #ffd600, #ff7a00, #ff0069, #d300c5, #7638fa)',
    },
    icon: LOGOS.instagram,
  },
  google: {
    id: 'google',
    label: 'Google',
    colorStyle: {
      backgroundColor: '#4285f4',
      // background:
      //   'linear-gradient(45deg, #4285F4, #4285F4 100%), linear-gradient(225deg, #F4B400, #F4B400), linear-gradient(135deg, #DB4437, #DB4437), linear-gradient(225deg, #0F9D58, #0F9D58)',
      // backgroundSize: '50% 50%',
      // backgroundPosition: '0% 0%, 0% 100%, 100% 0%, 100% 100%',
      // backgroundRepeat: 'no-repeat',
    },
    icon: LOGOS.google,
  },
  'facebook-page': {
    id: 'facebook-page',
    label: 'Facebook',
    colorStyle: { backgroundColor: '#1877f2' },
    icon: LOGOS.facebook,
  },
  'booking.com': {
    id: 'booking.com',
    label: 'Booking.com',
    colorStyle: { backgroundColor: '#003580' },
    icon: LOGOS.bookingCom,
  },
  'freshdesk-email': {
    id: 'freshdesk-email',
    label: 'Freshdesk',
    colorStyle: { backgroundColor: '#25c16f' },
    icon: LOGOS.freshdesk,
  },
  freshchat: {
    id: 'freshchat',
    label: 'Freshchat',
    colorStyle: { backgroundColor: '#25c16f' },
    icon: LOGOS.freshchat,
  },
  email: {
    id: 'email',
    label: 'Email',
    colorStyle: { backgroundColor: '#EC6C94' },
    icon: LOGOS.email,
  },
  'zendesk-email': {
    id: 'zendesk-email',
    label: 'Zendesk',
    colorStyle: { backgroundColor: 'rgb(23, 73, 77)' },
    icon: LOGOS.zendesk,
  },
  slack: {
    id: 'slack',
    label: 'Slack',
    colorStyle: { backgroundColor: '#4A154B' },
    icon: LOGOS.slack,
  },
  sms: {
    id: 'sms',
    label: 'SMS',
    colorStyle: { backgroundColor: '#4A154B' },
    icon: LOGOS.sms,
  },
  bookboost: {
    id: 'bookboost',
    label: 'Bookboost',
    colorStyle: { backgroundColor: '#3F1773' },
    icon: LOGOS.bookboost,
  },
  voice: {
    id: 'voice',
    label: 'Voice',
    colorStyle: { backgroundColor: '#223078' },
    icon: LOGOS.phone,
  },
};

// label value
export const AI_COMMUNICATION_CHANNELS = Object.keys(AI_CHAT_CHANNELS_CONFIG)
  .filter((channel) => channel !== 'default')
  .map(
    (channel) =>
      channel !== 'default' && {
        label: AI_CHAT_CHANNELS_CONFIG[channel].label,
        value: channel,
      }
  );

export const CONVERSATION_TABS = {
  inbox: 'INBOX',
  ai: 'AI',
  archived: 'ARCHIVE',
  starred: 'STARRED',
};

export const CONVERSATION_LIST_PAGE_SIZE = 30;

export const CONVERSATION_TAB_LIST = {
  [CONVERSATION_TABS.starred]: {
    id: 'starred',
    label: t`Starred`,
    value: 0,
  },
  [CONVERSATION_TABS.inbox]: { id: 'inbox', label: `Inbox`, value: 0 },
  [CONVERSATION_TABS.ai]: {
    id: 'ai',
    label: t`AI`,
    value: 0,
  },
  [CONVERSATION_TABS.archived]: {
    id: 'archived',
    label: t`Completed`,
    value: 0,
  },
};

export const MESSAGE_ACTIONS = {
  messaging: 'messaging',
  markComplete: 'mark_complete',
  housekeepingJobSubmission: 'housekeeping_job_submission',
  propertySelect: 'property_select',
  csatSurveyRequest: 'csat_survey_request',
  csatSurveyResponse: 'csat_survey_response',
};

export const MESSAGE_TYPES = {
  text: 'text',
  media: 'media',
  emoji: 'emoji',
  whatsappTemplate: 'whatsapp_template',
  optiiJobSubmissionResponse: 'optii_job_submission_response',
  chatWidgetInventoryCarousel: 'chat_widget_inventory_carousel',
  error: 'error_message', // used by test chat
  aiCsatRequest: 'ai_csat_request',
  humanCsatRequest: 'human_csat_request',
};

export const AI_TAGS = {
  modification: 'Modification',
  guestPreferences: 'Guest Preferences',
  luggageStorage: 'Luggage storage',
  refund: 'Refund',
  greetingsThankYou: 'Greetings/Thank you',
  paymentInformation: 'Payment Information',
  corporatePartnership: 'Corporate Partnership',
  cancellation: 'Cancellation',
  feedback: 'Feedback',
  checkinCheckout: 'Checkin/Checkout',
  invoiceRequests: 'Invoice Requests',
  maintenance: 'Maintenance',
  upsell: 'Upsell',
  inquiry: 'Inquiry',
  housekeeping: 'Housekeeping',
  internal: 'Internal',
  unhappyGuest: 'Unhappy guest',
};

export const CHAT_ACTOR = {
  visitor: 'visitor',
  ai: 'ai',
  platform_user: 'platformUser',
  pilot: 'pilot',
  system: 'system',
};

export const MESSAGE_TYPE_FORMATS = {
  md: 'md',
  html: 'html',
};
