import {
  formatDate,
  isDateWithInRange,
  DATE_FORMATS,
  getCountryName,
} from '@akin/core-lib/utils/dateTime';
import { t } from '@lingui/macro';
import { getLanguageName } from '../../utils';
import { CONVERSATION_LIST_PAGE_SIZE, CONVERSATION_TABS } from './constants';
import PAGE_ROUTES from '../../constants/pageRoutes';

export default function filterConversationList(list, filters) {
  if (Object.keys(filters).length === 0) return list;

  const { channel = [], dateRange = [] } = filters;

  const startDate = dateRange[0];
  const endDate = dateRange[1];

  return list.filter((con) => {
    const isChannelMatch =
      channel.length === 0 || channel.includes(con.communication_channel);

    const isDateRangeMatch =
      !startDate ||
      !endDate ||
      isDateWithInRange(con.last_message_at.date, startDate, endDate);

    return isChannelMatch && isDateRangeMatch;
  });
}

const getReservationOptionLabel = (reservation = {}) => {
  const formattedStartDate = formatDate(
    new Date(reservation.start_date),
    DATE_FORMATS.LONG3
  );
  if (reservation?.booking_source_confirmation_number) {
    return `${reservation.booking_source_confirmation_number} - ${formattedStartDate}`;
  }

  const formattedEndDate = formatDate(
    new Date(reservation.end_date),
    DATE_FORMATS.LONG3
  );

  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const processReservations = (reservations) => {
  const options = [];
  const reservationList = {};

  reservations.forEach((reservation) => {
    if (reservation?.pms_reservation_id) {
      const optionLabel = getReservationOptionLabel(reservation);
      options.push({
        value: reservation?.pms_reservation_id,
        label: optionLabel,
      });

      reservationList[reservation.pms_reservation_id] = { ...reservation };
    }
  });

  return { options, reservationList };
};

export const processUserDetails = (data) => {
  return [
    {
      label: t`Country`,
      value: data?.country
        ? getCountryName(data.country) || data.country
        : 'NA',
    },
    {
      label: t`Language`,
      value: data?.language
        ? getLanguageName(data.language) || data.language
        : 'NA',
    },
    {
      label: t`Email`,
      value: data?.email || 'NA',
    },
    {
      label: t`Phone`,
      value: data?.phone || 'NA',
    },
    {
      label: t`Channel`,
      value: data?.channel,
    },
  ];
};

export function getNotificationData(data) {
  const { user, lastMessage } = data;
  return {
    title: `${user?.name} says `,
    body: lastMessage,
    // link,
  };
}

export function getFetchConversationsPayload(filters) {
  const payload = {
    pageSize: CONVERSATION_LIST_PAGE_SIZE,
    communication_channels: [...filters.channels],
    tags: [...filters.tags],
  };

  if (filters.resolutionStatus !== null) {
    payload.resolution_status = filters.resolutionStatus;
  }

  if (filters.conversationId) {
    payload.conversation_id = filters.conversationId;
  }

  if (filters.startDate && filters.endDate) {
    payload.start_date = filters.startDate;
    payload.end_date = filters.endDate;
  }

  if (filters.state) {
    // Starred tab is just a flag and not a state
    // It is treated as a state === 'STARRED' only for the frontend
    if (filters.state === CONVERSATION_TABS.starred) {
      payload.is_starred = true;
    } else {
      payload.state = filters.state;
    }
  }

  if (filters.externalConversationId) {
    payload.external_conversation_id = filters.externalConversationId;
  }

  if (filters?.properties && filters?.properties?.length > 0) {
    payload.ai_subtenant_code = [...filters.properties];
  }

  return payload;
}

export function getLoadMoreButtonStatus({
  tabId,
  totalInbox = 0,
  totalAI = 0,
  totalArchived = 0,
  totalStarred = 0,
}) {
  if (
    tabId === CONVERSATION_TABS.inbox &&
    totalInbox <= CONVERSATION_LIST_PAGE_SIZE
  )
    return false;

  if (tabId === CONVERSATION_TABS.ai && totalAI <= CONVERSATION_LIST_PAGE_SIZE)
    return false;

  if (
    tabId === CONVERSATION_TABS.archived &&
    totalArchived <= CONVERSATION_LIST_PAGE_SIZE
  )
    return false;

  if (
    tabId === CONVERSATION_TABS.starred &&
    totalStarred <= CONVERSATION_LIST_PAGE_SIZE
  )
    return false;

  return true;
}

export function getDefaultSelectedConversation({
  conversationList,
  conversationPositions,
  selectedConversationId,
}) {
  // use-case: Conversation is moved form AI tab to INBOX tab / Mark conversation Completed
  // then the selected conversation ID should remain the same but the conversation list is updated
  if (selectedConversationId && conversationList?.[selectedConversationId]) {
    return conversationList?.[selectedConversationId];
  }

  return conversationList?.[conversationPositions[0]];
}

// get inbox conversation link via space code and external conversation id
export function getInboxConversationLink(spaceCode, externalConversationId) {
  return `${process.env.NEXT_PUBLIC_APP_BASE_URL}/${spaceCode}${PAGE_ROUTES.inbox}/${externalConversationId}`;
}

export function isFiltersApplied(searchFilters = {}, isGroup = false) {
  const status =
    searchFilters?.channels?.length ||
    (searchFilters.startDate && searchFilters.endDate) ||
    searchFilters?.tags?.length ||
    searchFilters.resolutionStatus !== null ||
    searchFilters.externalConversationId ||
    searchFilters.conversationId;

  if (isGroup && searchFilters?.properties?.length > 0) {
    return true;
  }

  return status;
}
