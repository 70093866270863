import { useMemo } from 'react';
import { t } from '@lingui/macro';
import { format } from 'date-fns';
import { ActionIcon, Tooltip } from '@mantine/core';
import { Image } from '@akin/ui-lib';
import ICONS from '@akin/core-lib/icons';
import DataTable from '../../common/data-table';
import GraphCard from '../common/GraphCard';
import useSharedStyles from '../sharedStyles';
import { getInboxConversationLink } from '../../chat/utils';
import useSpace from '../../../hooks/useSpace';

function ConversationCell({ value }) {
  const { currentSpace } = useSpace();

  // Construct the link using the space code and conversation ID
  const link = getInboxConversationLink(currentSpace?.space_code, value);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Tooltip label={t`Open conversation`}>
        <ActionIcon
          variant="subtle"
          onClick={() => window.open(link, '_blank', 'noopener,noreferrer')}
        >
          <Image
            src={ICONS.newTab}
            width={16}
            height={16}
            style={{
              filter:
                'invert(80%) sepia(2%) saturate(0%) hue-rotate(137deg) brightness(86%) contrast(90%)',
            }}
          />
        </ActionIcon>
      </Tooltip>
    </div>
  );
}

function TimeCell({ value }) {
  return format(new Date(value), 'd MMM, h:mmaaa').replace(
    /(\w+)/g,
    (w) => w[0].toUpperCase() + w.slice(1).toLowerCase()
  );
}

function CenterAlignCell({ value }) {
  return <div style={{ textAlign: 'center' }}>{value}</div>;
}

export default function CSATScoresTable({ data }) {
  const { classes } = useSharedStyles();
  const { currentSpace } = useSpace();

  const columns = useMemo(
    () => [
      {
        id: 'timestamp',
        title: t`Time`,
        accessor: 'timestamp',
        Cell: TimeCell,
        width: 20,
      },
      {
        id: 'score',
        title: t`CSAT Score`,
        accessor: 'score',
        style: { textAlign: 'center' },
        Cell: CenterAlignCell,
        excelStyle: { alignment: { horizontal: 'center' } },
      },
      {
        id: 'type',
        title: t`Type`,
        accessor: 'type',
        style: { textAlign: 'center' },
        Cell: CenterAlignCell,
        excelStyle: { alignment: { horizontal: 'center' } },
      },
      {
        id: 'comment',
        title: t`Comment`,
        accessor: 'comment',
        width: 40,
      },
      {
        id: 'tags',
        title: t`Tags`,
        accessor: 'tags',
        width: 40,
      },
      {
        id: 'externalConversationId',
        title: t`Conversation`,
        accessor: 'externalConversationId',
        style: { textAlign: 'center' },
        Cell: ConversationCell,
        width: 60,
      },
    ],
    [currentSpace?.space_code]
  );

  return (
    <GraphCard.Content className={classes.overflowYGraph}>
      <DataTable
        columns={columns}
        data={data}
        title={`CSAT Scores - ${currentSpace?.name || ''}`}
        options={{
          paging: true,
          pageSizeOptions: [10],
          hidePageSelect: true,
          hideTitle: true,
          allowExport: {
            excel: true,
            pdf: false,
          },
        }}
      />
    </GraphCard.Content>
  );
}
