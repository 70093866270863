import { t } from '@lingui/macro';
import { AI_HUMAN_LABELS } from './constants';

export function getCsatScoreGraphData(data = {}) {
  if (!data?.total) {
    return [{ id: null, label: t`No data`, value: '' }];
  }
  return [
    {
      id: AI_HUMAN_LABELS.ai,
      label: AI_HUMAN_LABELS.ai,
      value: data.total.ai_count,
    },
    {
      id: AI_HUMAN_LABELS.human,
      label: AI_HUMAN_LABELS.human,
      value: data.total.human_count,
    },
  ];
}

export function getCsatScoreTableData(data = {}) {
  const scores = data?.scores || [];
  return scores?.map((score) => ({
    timestamp: score?.created_at,
    score: score?.score,
    type: score?.type === 'HUMAN' ? AI_HUMAN_LABELS.human : score?.type,
    comment: score?.comment || '-',
    tags: score?.tags?.join(', ') || '-',
    externalConversationId: score?.external_conversation_id,
  }));
}

export function getProcessedCsatData({ data = {} }) {
  return {
    csatScoreTableData: getCsatScoreTableData(data),
    csatResponseRateGraphData: getCsatScoreGraphData(data),
  };
}
