import { useMediaQuery } from '@mantine/hooks';
import PieChart from '../../common/charts/PieChart';
import GraphCard from '../common/GraphCard';

export default function LanguageGraph({ graphInfo }) {
  const isMobileScreen = useMediaQuery('(max-width: 450px)');
  const margin = isMobileScreen
    ? { top: 40, right: 50, bottom: 80, left: 10 }
    : { top: 40, right: 80, bottom: 80, left: 80 };

  return (
    <GraphCard.Content overflow={false}>
      <PieChart
        data={graphInfo}
        showLegend={!isMobileScreen}
        margin={margin}
        graphData={{
          legends: [
            {
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 40,
              translateY: 56,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: '#999',
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#000',
                  },
                },
              ],
            },
          ],
        }}
      />
    </GraphCard.Content>
  );
}
