import { Text } from '@akin/ui-lib';
import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    '&.center': {
      justifyContent: 'center',
    },
    [theme.fn.smallerThan('xs')]: {
      gap: 0,
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: 10,
    },
  },
}));

export default function GraphInsights({
  title,
  subTitle = false,
  description,
  center = false,
}) {
  const { cx, classes } = useStyles();

  return (
    <div className={cx(classes.container, { center })}>
      <Text weight={subTitle ? 400 : 600} size={subTitle ? 'sm' : ''}>
        {title}:
      </Text>
      <Text size="sm">{description}</Text>
    </div>
  );
}
