import { Select, Text, UnstyledButton } from '@akin/ui-lib';
import { t } from '@lingui/macro';
import { createStyles, Group } from '@mantine/core';
import ReloadIcon from '@akin/ui-lib/svg/ReloadIcon';
import ThreeHexagons from '@akin/ui-lib/svg/ThreeHexagons';

import TimeRangeFilter from '../../common/time-range-filter';
import { AI_HUMAN_LABELS, HUMAN_ALL } from '../constants';

const useStyles = createStyles((theme) => ({
  filterContainer: {
    display: 'flex',
    gap: 10,
    [theme.fn.smallerThan('sm')]: {
      margin: '0px -10px',
      padding: 8,
      flexDirection: 'column',
    },
  },
  flex: {
    display: 'flex',
    gap: 10,
    flex: 1,
  },
  filter: {
    flex: 1,
    '& .mantine-Select-input': {
      border: 'none!important',
    },
    [theme.fn.smallerThan('xs')]: {
      minWidth: 80,
    },
  },
  select: {
    width: 250,
    [theme.fn.smallerThan('xs')]: {
      width: 'unset',
    },
    '& .mantine-Select-input': {
      border: 'none!important',
    },
  },
  reloadButton: {
    backgroundColor: theme.white,
    borderRadius: 8,
    padding: 10,
    height: 36,
  },
}));

const iconStyle = {
  width: 22,
  height: 22,
  borderRadius: '50%',
  backgroundColor: '#9ca3af',
  color: '#fff',
  fontSize: 12,
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const filterList = [
  {
    icon: <ThreeHexagons width="16" height="16" fill="#9ca3af" />,
    label: AI_HUMAN_LABELS?.[HUMAN_ALL.human],
    value: HUMAN_ALL.human,
  },
  {
    icon: <div style={iconStyle}>{AI_HUMAN_LABELS?.[HUMAN_ALL.ai]}</div>,
    label: AI_HUMAN_LABELS?.[HUMAN_ALL.ai],
    value: HUMAN_ALL.ai,
  },
];

function Item({ icon, label, ...others }) {
  return (
    <div {...others}>
      <Group noWrap>
        {icon || null}
        <div>
          <Text size="sm">{label}</Text>
        </div>
      </Group>
    </div>
  );
}

export default function FilterPanel({
  channelList,
  onSelectChannel,
  selectedChannel,
  onSelectPeriod,
  selectedPeriod,
  onRefresh,
  disableFilter = false,
  selectedFilter,
  setSelectedFilter,
}) {
  const { classes } = useStyles();
  return (
    <div className={classes.filterContainer}>
      <div className={classes.flex}>
        <UnstyledButton className={classes.reloadButton} onClick={onRefresh}>
          <ReloadIcon
            width="16"
            height="16"
            strokeWidth="2.5"
            stroke="#1ABA83"
          />
        </UnstyledButton>

        <Select
          placeholder={t`Search Filter`}
          className={classes.filter}
          disabled={disableFilter}
          itemComponent={Item}
          data={filterList}
          maxDropdownHeight={400}
          value={selectedFilter}
          onChange={setSelectedFilter}
          clearable
          // searchable
          // filter={(value, item) =>
          //   item.label.toLowerCase().includes(value.toLowerCase().trim())
          // }
        />

        <Select
          id="channel"
          name="channel"
          data={channelList}
          maxDropdownHeight={280}
          onChange={(value) => onSelectChannel(value)}
          value={selectedChannel}
          withinPortal
          required
          {...(!channelList.length && {
            placeholder: t`No channels configured`,
            disabled: true,
          })}
          className={classes.select}
        />
      </div>

      <TimeRangeFilter
        disabled={!channelList.length}
        onClick={onSelectPeriod}
        selected={selectedPeriod}
      />
    </div>
  );
}
