import { useMemo, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { t } from '@lingui/macro';

import PieChart from '../../common/charts/PieChart';
import GraphInsights from '../GraphInsights';
import { HUMAN_AI_ALL_LV, HUMAN_ALL } from '../constants';
import GraphCard from '../common/GraphCard';

const mobileMargin = { top: 40, right: 50, bottom: 80, left: 10 };
const desktopMargin = { top: 40, right: 80, bottom: 80, left: 80 };

export default function ProblemSolvedGraph({ graphInfo: data }) {
  const isMobileScreen = useMediaQuery('(max-width: 450px)');
  const [typeGraph, setTypeGraph] = useState(HUMAN_ALL.ai);

  const margin = isMobileScreen ? mobileMargin : desktopMargin;

  const graphInfo = useMemo(() => {
    if (typeGraph === HUMAN_ALL.ai) return data.ai;
    if (typeGraph === HUMAN_ALL.all) return data.all;
    if (typeGraph === HUMAN_ALL.human) return data.human;
  }, [typeGraph, data.ai, data.all, data.human]);

  return (
    <>
      <GraphCard.Header
        showSwitch
        switchList={HUMAN_AI_ALL_LV}
        onSwitchChange={setTypeGraph}
        selected={typeGraph}
      >
        <GraphInsights title={t`Was the problem solved`} />
      </GraphCard.Header>

      <GraphCard.Content overflow={false}>
        <PieChart
          data={graphInfo}
          showLegend={false}
          margin={margin}
          graphData={{
            legends: [
              {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 40,
                translateY: 56,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemTextColor: '#000',
                    },
                  },
                ],
              },
            ],
          }}
        />
      </GraphCard.Content>
    </>
  );
}
