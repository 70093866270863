import { formatDate, DATE_FORMATS } from '@akin/core-lib/utils/dateTime';
import { t } from '@lingui/macro';

import {
  INTERVALS,
  IntervalVariationsByLanguage,
  LIST_TYPE,
  TIME_PERIOD,
  TIME_PERIOD_LIST,
} from './constants';

// find the interval from the search input
export function getInterval(input, language = 'en') {
  let foundInterval = null;

  Object.keys(IntervalVariationsByLanguage[language]).forEach((interval) => {
    if (
      IntervalVariationsByLanguage[language][interval].includes(
        input.toLowerCase()
      )
    ) {
      foundInterval = INTERVALS?.[interval]?.id;
    }
  });

  return foundInterval;
}

// Get Time Range Text to be displayed in button
export function getRangeButtonText({ value, id, label }) {
  if (TIME_PERIOD[value]) {
    return TIME_PERIOD[value].label;
  }

  if (id === LIST_TYPE.calendar) {
    const startDate = formatDate(value.startDate, DATE_FORMATS.DEFAULT2);
    const endDate = formatDate(value.endDate, DATE_FORMATS.DEFAULT2);

    return `${startDate}:${value.startTime} - ${endDate}:${value.startTime}`;
  }

  if (id === LIST_TYPE.search) {
    return label;
  }
}

// get Interval array
function getIntervalArray(caseIntervals = [], selectedInterval = null) {
  if (!selectedInterval) return caseIntervals;
  if (caseIntervals.includes(selectedInterval)) {
    return [selectedInterval];
  }
  return [];
}

export function generateFilteredTimeIntervals(number, selectedInterval = null) {
  const intervals = [
    INTERVALS.minute.id,
    INTERVALS.hour.id,
    INTERVALS.day.id,
    INTERVALS.week.id,
    INTERVALS.month.id,
  ];
  let result = [];

  switch (true) {
    case number === 0 || Number.isNaN(number) || number === '':
      result = [];
      break;
    case number <= 3:
      result = intervals;
      break;
    case number <= 12:
      result = intervals.slice(0, 4);
      break;
    case number <= 90:
      result = intervals.slice(0, 3);
      break;
    case number <= 2160:
      result = intervals.slice(0, 2);
      break;
    case number <= 129600:
      result = intervals.slice(0, 1);
      break;
    default:
      result = [];
      break;
  }

  result = getIntervalArray(result, selectedInterval);

  return result.map((interval) => ({
    value: number + interval,
    interval,
    timeValue: number,
    label: t`Last ${number} ${INTERVALS?.[interval]?.label}`,
  }));
}

export function processReportsParams(
  { value, id, interval, timeValue, timezone },
  tab = 'overview'
) {
  if (id === LIST_TYPE.default && TIME_PERIOD[value]) {
    switch (value) {
      case TIME_PERIOD.TODAY.value:
        return { timePeriod: { value: 1, unit: INTERVALS.today.id } };

      case TIME_PERIOD.LAST_24_HOURS.value:
        return { timePeriod: { value: 24, unit: INTERVALS.hour.id } };

      case TIME_PERIOD.LAST_WEEK.value:
        return { timePeriod: { value: 1, unit: INTERVALS.week.id } };

      case TIME_PERIOD.LAST_MONTH.value:
        return { timePeriod: { value: 1, unit: INTERVALS.month.id } };

      default:
        return null;
    }
  }

  if (id === LIST_TYPE.calendar && tab === 'overview') {
    const startDate = formatDate(value.startDate, DATE_FORMATS.DEFAULT2);
    const endDate = formatDate(value.endDate, DATE_FORMATS.DEFAULT2);
    return {
      startDatetime: `${startDate}T${value.startTime}Z`,
      endDatetime: `${endDate}T${value.endTime}Z`,
    };
  }

  if (id === LIST_TYPE.calendar && tab === 'csat') {
    const startDate = formatDate(value.startDate, DATE_FORMATS.DEFAULT2);
    const endDate = formatDate(value.endDate, DATE_FORMATS.DEFAULT2);
    return {
      start: {
        local_date: startDate,
        local_time: value.startTime,
        timezone,
      },
      end: {
        local_date: endDate,
        local_time: value.endTime,
        timezone,
      },
    };
  }

  if (id === LIST_TYPE.search) {
    return { timePeriod: { value: timeValue, unit: interval } };
  }
}

function splitNumberTextInput(input) {
  // Regular expression to match a number at any position in the string
  const regex = /(\d+)(\D*)(.*)/;

  // Use regex to match the input
  const match = input.match(regex);

  // If match is found
  if (match) {
    const number = parseInt(match[1], 10); // Parse the matched number
    const text = (match[2] + match[3]).trim(); // Combine the matched text parts and trim any leading/trailing spaces
    // Return the number and text if both are present, otherwise just the number
    return text ? [number, text] : [number];
  }
  // If no match is found, return an empty array
  return [];
}

export function getTimeList(keyword, selectedPeriod) {
  if (!keyword && selectedPeriod && selectedPeriod.id === LIST_TYPE.search) {
    return [selectedPeriod, ...TIME_PERIOD_LIST];
  }
  if (!keyword) {
    return TIME_PERIOD_LIST;
  }

  if (keyword) {
    const browserLanguage =
      localStorage.getItem('LOCALE') || navigator.language || 'en';
    const [number, text = null] = splitNumberTextInput(keyword);
    const selectedInterval = text ? getInterval(text, browserLanguage) : null;
    const results =
      !selectedInterval && text
        ? []
        : generateFilteredTimeIntervals(number, selectedInterval);
    return results;
  }
}
